@media(max-width: 750px) {
   .market_images {
      height: 37px !important;
   }
}

.market_images {
   height: 40px;
}

body {
   background: #33373a;
}

